import actionTypes from "../actionTypes";
import IAction from "../../types/IAction";
import Estado from "../../types/codigoPostal";
import Municipio from "../../types/codigoPostal";
import Localidad from "../../types/codigoPostal";
import {
  errorNotification,
  infoNotification,
  successNotification
} from "../../utils/notifications";
import LocalidadDuplicada from "../../types/codigoPostal";

export interface GetAllActiveEstados {
  estados: Estado[];
}

export interface GetAlActiveMunicipiosByEstado {
  municipios: Municipio[];
}

export interface BuscarLocalidades {
  localidades: Localidad[];
}

interface State
  extends GetAllActiveEstados,
    GetAlActiveMunicipiosByEstado,
    BuscarLocalidades {
  searching: boolean;
  saving: boolean;
  deleting: boolean;
  localidadesDuplicadas: LocalidadDuplicada[];
}

const initialState: State = {
  estados: null,
  municipios: null,
  localidades: null,
  searching: false,
  saving: false,
  deleting: false,
  localidadesDuplicadas: []
};

export default function codigoPostalReducer(
  state: State = initialState,
  action: IAction
): State {
  const snapshot = snapshotAction(state, action);

  state != snapshot &&
    !action.type.endsWith("_PENDING") &&
    action.meta &&
    action.meta.resolver &&
    action.meta.resolver({
      fulfilled: action.type.endsWith("_FULFILLED"),
      ...snapshot
    });

  return state != snapshot
    ? {
        ...state,
        ...snapshot
      }
    : state;
}

function snapshotAction(
  state: State = initialState,
  action: IAction
): Partial<State> {
  switch (action.type) {
    case `${actionTypes.GET_ALL_ACTIVE_ESTADOS}_PENDING`: {
      infoNotification("Obteniendo los estados");
      return {
        estados: null
      };
    }
    case `${actionTypes.GET_ALL_ACTIVE_ESTADOS}_REJECTED`: {
      errorNotification("Error al obtener los estados");
      return {
        estados: null
      };
    }
    case `${actionTypes.GET_ALL_ACTIVE_ESTADOS}_FULFILLED`: {
      successNotification("Estados cargados correctamente");
      return {
        ...state,
        estados: action.payload.data
      };
    }
    case `${actionTypes.GET_ALL_ACTIVE_MUNICIPIOS_BY_ESTADO}_PENDING`: {
      infoNotification("Obteniendo los municipios");
      return {
        municipios: null
      };
    }
    case `${actionTypes.GET_ALL_ACTIVE_MUNICIPIOS_BY_ESTADO}_REJECTED`: {
      errorNotification("Error al obtener los municipios");
      return {
        municipios: null
      };
    }
    case `${actionTypes.GET_ALL_ACTIVE_MUNICIPIOS_BY_ESTADO}_FULFILLED`: {
      successNotification("Municipios cargados correctamente");
      return {
        ...state,
        municipios: action.payload.data
      };
    }
    case `${actionTypes.GET_LOCALIDADES_DUPLICADAS}_PENDING`: {
      infoNotification("Obteniendo las localidades duplicadas");
      return {
        ...state,
        localidadesDuplicadas: []
      };
    }
    case `${actionTypes.GET_LOCALIDADES_DUPLICADAS}_REJECTED`: {
      errorNotification("Error al obtener las localidades duplicadas");
      return {
        ...state,
        localidadesDuplicadas: []
      };
    }
    case `${actionTypes.GET_LOCALIDADES_DUPLICADAS}_FULFILLED`: {
      successNotification("Localidades duplicadas cargadas correctamente");
      return {
        ...state,
        localidadesDuplicadas: action.payload.data
      };
    }
    case `${actionTypes.POST_LOCALIDAD}_PENDING`: {
      infoNotification("Creando la localidad");
      return {
        localidades: null
      };
    }
    case `${actionTypes.POST_LOCALIDAD}_REJECTED`: {
      errorNotification("Error al crear la localidad");
      return {
        localidades: null
      };
    }
    case `${actionTypes.POST_LOCALIDAD}_FULFILLED`: {
      successNotification("Localidad creada correctamente");
      return {
        ...state,
        localidades: action.payload.data
      };
    }
    case `${actionTypes.GET_LOCALIDADES_CUSTOM}_PENDING`: {
      infoNotification("Obteniendo las localidades con los filtros");
      return {
        localidades: null
      };
    }
    case `${actionTypes.GET_LOCALIDADES_CUSTOM}_REJECTED`: {
      errorNotification("Error al obtener las localidades con los filtros");
      return {
        localidades: null
      };
    }
    case `${actionTypes.GET_LOCALIDADES_CUSTOM}_FULFILLED`: {
      successNotification("Localidades cargadas correctamente con los filtros");
      return {
        ...state,
        localidades: action.payload.data
      };
    }
    case `${actionTypes.PUT_LOCALIDAD}_PENDING`: {
      infoNotification("Actualizando la localidad");
      return {
        localidades: null
      };
    }
    case `${actionTypes.PUT_LOCALIDAD}_REJECTED`: {
      errorNotification("Error al actualizar la localidad");
      return {
        localidades: null
      };
    }
    case `${actionTypes.PUT_LOCALIDAD}_FULFILLED`: {
      successNotification("Localidad actualizada correctamente");
      return {
        ...state
      };
    }
    case `${actionTypes.DELETE_LOCALIDAD}_PENDING`: {
      infoNotification("Eliminando la localidad");
      return {
        ...state,
        deleting: true
      };
    }
    case `${actionTypes.DELETE_LOCALIDAD}_REJECTED`: {
      errorNotification("Error al eliminar la localidad");
      return {
        ...state,
        deleting: false
      };
    }
    case `${actionTypes.DELETE_LOCALIDAD}_FULFILLED`: {
      const localidadId = action.meta.id;
      successNotification("Localidad eliminada correctamente");
      return {
        ...state,
        localidades: state.localidades
          ? state.localidades.filter(loc => loc.localidadId !== localidadId)
          : [],
        deleting: false
      };
    }
    default: {
      return state;
    }
  }
}
