import React, { Component } from "react";
import {
  Router,
  RouterProps,
  Styled
} from "../../../toolkit/decorators/Artifact";
import { CodigoPostalDispatcher } from "../../containers/codigoPostal/codigoPostal.dispatcher";
import { CodigoPostalStore } from "../../containers/codigoPostal/codigoPostal.store";
import s from "./styles.scss";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import ConfirmModal from "../common/confirmModal/confirmModal";
import { CodigoPostalTable } from "./CodigoPostal.table";
import Localidad from "../../../types/codigoPostal";
import LocalidadDuplicada from "../../../types/codigoPostal";
import {
  errorNotification,
  successNotification
} from "../../../utils/notifications";
import LocalidadesDuplicadasModal from "./CodigoPostalDuplicadosModal";

interface Props
  extends CodigoPostalStore,
    CodigoPostalDispatcher,
    RouterProps<any> {}

interface State {
  confirmPopupOpen: boolean;
  confirmPopupId: number | null;
  modalOpen: boolean;
  editingLocalidad: Localidad | null;
  formValues: {
    codigoPostal: string;
    estado: string;
    municipio: string;
    localidad: string;
  };
  formValuesModal: {
    codigoPostal: string;
    estado: string;
    municipio: string;
    localidad: string;
  };
  duplicados: LocalidadDuplicada[];
}

@Router()
@Styled(s)
export default class CodigoPostalComponent extends Component<Props, State> {
  state = {
    confirmPopupOpen: false,
    confirmPopupId: null,
    modalOpen: false,
    editingLocalidad: null,
    formValues: {
      codigoPostal: "",
      estado: "",
      municipio: "",
      localidad: ""
    },
    formValuesModal: {
      codigoPostal: "",
      estado: "",
      municipio: "",
      localidad: ""
    },
    duplicados: []
  };

  componentDidMount() {
    this.fetchInitialData();
  }

  fetchInitialData = async () => {
    try {
      await this.props.getAllActiveEstados();
    } catch (error) {
      console.error("Error al cargar estados:", error);
      errorNotification("Error al cargar la lista de estados");
    }
  };

  handleInputChange = e => {
    const { name, value } = e.target;
    this.setState({
      formValues: {
        ...this.state.formValues,
        [name]: value
      }
    });

    if (name === "estado" && value) {
      this.props.getAllActiveMunicipiosByEstado(parseInt(value));
    }
  };

  handleModalInputChange = e => {
    const { name, value } = e.target;
    this.setState({
      formValuesModal: {
        ...this.state.formValuesModal,
        [name]: value
      }
    });

    if (name === "estado" && value) {
      this.props.getAllActiveMunicipiosByEstado(parseInt(value));
      this.setState({ duplicados: [] });
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    const {
      codigoPostal,
      estado,
      municipio,
      localidad
    } = this.state.formValues;

    if (!municipio) {
      errorNotification(
        "Debe seleccionar un municipio para realizar la búsqueda"
      );
      return;
    }

    this.props
      .buscarLocalidades(parseInt(municipio), codigoPostal, localidad || null)
      .then(response => {
        if (response.localidades && response.localidades.length === 0) {
          errorNotification(
            "No se encontraron localidades con los criterios seleccionados"
          );
        }
      })
      .catch(error => {
        console.error("Error en la búsqueda:", error);
        errorNotification("Ocurrió un error al buscar localidades");
      });
  };

  handleModalSubmit = async e => {
    e.preventDefault();
    const {
      codigoPostal,
      estado,
      municipio,
      localidad
    } = this.state.formValuesModal;

    // Validaciones básicas
    if (!codigoPostal) {
      errorNotification("El código postal es requerido");
      return;
    }
    if (!estado) {
      errorNotification("Debe seleccionar un estado");
      return;
    }
    if (!municipio) {
      errorNotification("Debe seleccionar un municipio");
      return;
    }
    if (!localidad) {
      errorNotification("El nombre de la localidad es requerido");
      return;
    }

    try {
      const estadoId = parseInt(estado);
      const municipioId = parseInt(municipio);
      const codigoId = parseInt(codigoPostal);

      if (!isNaN(municipioId) && !isNaN(codigoId) && localidad.trim() !== "") {
        const response = await this.props.getLocalidadesDuplicadas(
          municipioId,
          codigoId,
          localidad
        );

        if (
          response &&
          this.props.localidadesDuplicadas &&
          this.props.localidadesDuplicadas.length > 0
        ) {
          errorNotification(
            "No se puede guardar. Existen localidades duplicadas."
          );
          return;
        }

        const localidadData = {
          codigoPostal: codigoId,
          estadoId: estadoId,
          municipioId: municipioId,
          localidad: localidad
        };

        let resultado;

        if (this.state.editingLocalidad) {
          const localidadId = this.state.editingLocalidad.localidadId;
          resultado = await this.props.actualizarLocalidad(localidadId, {
            ...localidadData,
            localidadId: localidadId
          });
          successNotification("Localidad actualizada correctamente");
        } else {
          resultado = await this.props.guardarLocalidad(localidadData);
          successNotification("Localidad creada correctamente");
        }

        this.setState({
          modalOpen: false,
          editingLocalidad: null,
          formValuesModal: {
            codigoPostal: "",
            estado: "",
            municipio: "",
            localidad: ""
          }
        });

        if (this.state.formValues.municipio) {
          await this.props.buscarLocalidades(
            parseInt(this.state.formValues.municipio),
            this.state.formValues.codigoPostal,
            this.state.formValues.localidad || null
          );
        }
      }
    } catch (error) {
      console.error("Error al guardar/actualizar la localidad:", error);
      errorNotification("Error al guardar la localidad");
    }
  };

  onAddClick = () => {
    this.setState({
      modalOpen: true,
      editingLocalidad: null,
      formValuesModal: {
        codigoPostal: "",
        estado: "",
        municipio: "",
        localidad: ""
      }
    });
  };

  onEditClick = (localidad: Localidad) => {
    if (localidad.estado) {
      const estadoEncontrado = this.props.estados.find(
        e => e.nombre === localidad.estado
      );
      if (estadoEncontrado) {
        this.props.getAllActiveMunicipiosByEstado(estadoEncontrado.estadoId);
      }
    }

    this.setState({
      modalOpen: true,
      editingLocalidad: localidad,
      formValuesModal: {
        codigoPostal: localidad.codigoPostal || "",
        estado: this.getEstadoIdByNombre(localidad.estado),
        municipio: this.getMunicipioIdByNombre(localidad.municipio),
        localidad: localidad.nombre || ""
      }
    });
  };

  getEstadoIdByNombre(nombre: string): string {
    const estado = this.props.estados.find(e => e.nombre === nombre);
    return estado ? estado.estadoId.toString() : "";
  }

  getMunicipioIdByNombre(nombre: string): string {
    const municipio = this.props.municipios.find(m => m.nombre === nombre);
    return municipio ? municipio.municipioId.toString() : "";
  }

  onDeleteClick = (id: number) => {
    this.setState({
      confirmPopupOpen: true,
      confirmPopupId: id
    });
  };

  confirmDelete = async () => {
    const { confirmPopupId, formValues } = this.state;
    await this.props.eliminarLocalidad(confirmPopupId);

    if (formValues.municipio) {
      await this.props.buscarLocalidades(
        parseInt(this.state.formValues.municipio),
        this.state.formValues.codigoPostal,
        this.state.formValues.localidad || null
      );
    }

    this.setState({
      confirmPopupOpen: false,
      confirmPopupId: null
    });
  };

  toggleModal = () => {
    this.setState({
      modalOpen: !this.state.modalOpen,
      editingLocalidad: null,
      formValuesModal: {
        codigoPostal: "",
        estado: "",
        municipio: "",
        localidad: ""
      },
      duplicados: []
    });
  };

  render() {
    const { estados, municipios, localidades, searching } = this.props;
    const {
      formValues,
      formValuesModal,
      modalOpen,
      editingLocalidad
    } = this.state;

    return (
      <Container className="mb-5">
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col md={3}>
              <FormGroup>
                <Label for="codigoPostal">Código Postal</Label>
                <Input
                  type="text"
                  name="codigoPostal"
                  id="codigoPostal"
                  placeholder="Ingrese código postal"
                  value={formValues.codigoPostal}
                  onChange={this.handleInputChange}
                  maxLength={5}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label for="estado">Estado</Label>
                <Input
                  type="select"
                  name="estado"
                  id="estado"
                  value={formValues.estado}
                  onChange={this.handleInputChange}
                  disabled={!estados || estados.length === 0}
                >
                  <option value="">Seleccione estado</option>
                  {estados &&
                    estados.map(estado => (
                      <option key={estado.estadoId} value={estado.estadoId}>
                        {estado.nombre}
                      </option>
                    ))}
                </Input>
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label for="municipio">Municipio</Label>
                <Input
                  type="select"
                  name="municipio"
                  id="municipio"
                  value={formValues.municipio}
                  onChange={this.handleInputChange}
                  disabled={
                    !formValues.estado || !municipios || municipios.length === 0
                  }
                >
                  <option value="">Seleccione municipio</option>
                  {municipios &&
                    municipios.map(municipio => (
                      <option
                        key={municipio.municipioId}
                        value={municipio.municipioId}
                      >
                        {municipio.nombre}
                      </option>
                    ))}
                </Input>
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label for="localidad">Localidad</Label>
                <Input
                  type="text"
                  name="localidad"
                  id="localidad"
                  placeholder="Ingrese nombre de localidad"
                  value={formValues.localidad}
                  onChange={this.handleInputChange}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md={{ size: 3, offset: 9 }}>
              <Button color="primary" type="submit" block disabled={searching}>
                Buscar
              </Button>
            </Col>
          </Row>
        </Form>

        <div className="mt-4">
          <CodigoPostalTable
            page={localidades}
            onAddClick={this.onAddClick}
            onEditClick={this.onEditClick}
            onDeleteClick={this.onDeleteClick}
          />
        </div>

        <ConfirmModal
          title="Confirmar eliminación"
          message="¿Está seguro de eliminar esta localidad?"
          isOpen={this.state.confirmPopupOpen}
          ok={this.confirmDelete}
          cancel={() => this.setState({ confirmPopupOpen: false })}
        />

        <Modal isOpen={modalOpen} toggle={this.toggleModal} size="lg">
          <ModalHeader toggle={this.toggleModal}>
            {editingLocalidad ? "Editar Localidad" : "Crear Nueva Localidad"}
          </ModalHeader>
          <ModalBody>
            <Form>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="modalCodigoPostal">Código Postal</Label>
                    <Input
                      type="text"
                      name="codigoPostal"
                      id="modalCodigoPostal"
                      placeholder="Ingrese código postal"
                      value={formValuesModal.codigoPostal}
                      onChange={this.handleModalInputChange}
                      maxLength={5}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="modalEstado">Estado</Label>
                    <Input
                      type="select"
                      name="estado"
                      id="modalEstado"
                      value={formValuesModal.estado}
                      onChange={this.handleModalInputChange}
                      disabled={!estados || estados.length === 0}
                    >
                      <option value="">Seleccione estado</option>
                      {estados &&
                        estados.map(estado => (
                          <option key={estado.estadoId} value={estado.estadoId}>
                            {estado.nombre}
                          </option>
                        ))}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="modalMunicipio">Municipio</Label>
                    <Input
                      type="select"
                      name="municipio"
                      id="modalMunicipio"
                      value={formValuesModal.municipio}
                      onChange={this.handleModalInputChange}
                      disabled={
                        !formValuesModal.estado ||
                        !municipios ||
                        municipios.length === 0
                      }
                    >
                      <option value="">Seleccione municipio</option>
                      {municipios &&
                        municipios.map(municipio => (
                          <option
                            key={municipio.municipioId}
                            value={municipio.municipioId}
                          >
                            {municipio.nombre}
                          </option>
                        ))}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="modalLocalidad">Localidad</Label>
                    <Input
                      type="text"
                      name="localidad"
                      id="modalLocalidad"
                      placeholder="Ingrese nombre de localidad"
                      value={formValuesModal.localidad}
                      onChange={this.handleModalInputChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {this.props.localidadesDuplicadas &&
                this.props.localidadesDuplicadas.length > 0 && (
                  <Row>
                    <Col md={12}>
                      <LocalidadesDuplicadasModal
                        duplicados={this.props.localidadesDuplicadas}
                      />
                    </Col>
                  </Row>
                )}
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggleModal}>
              Cancelar
            </Button>
            <Button color="primary" onClick={this.handleModalSubmit}>
              Guardar
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    );
  }
}
