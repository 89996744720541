import React, { Component } from "react";
import {
  Router,
  RouterProps,
  Styled
} from "../../../toolkit/decorators/Artifact";
import { ProductosSatDispatcher } from "../../containers/productosSat/productosSat.dispatcher";
import { ProductosSatStore } from "../../containers/productosSat/productosSat.store";
import s from "./styles.scss";
import { Container } from "reactstrap";
import FormBuilder from "../../../toolkit/baseForms2/formBuilder/FormBuilder";
import {
  Inputs,
  Buttons,
  formularioBusquedaProductosSat
} from "./ProductosSat.form";
import ConfirmModal from "../common/confirmModal/confirmModal";
import { ProductosSatTable } from "./ProductosSat.table";
import ProductoSat from "../../../types/ProductoSat";
import ProductoSatModal from "./ProductoSatModal.component";
import {
  errorNotification,
  successNotification
} from "../../../utils/notifications";

interface Props
  extends ProductosSatStore,
    ProductosSatDispatcher,
    RouterProps<any> {}

interface State {
  confirmPopupOpen: boolean;
  confirmPopupId: number | null;
  modalOpen: boolean;
  editingProducto: ProductoSat | null;
}

@Router()
@Styled(s)
export default class ProductosSatComponent extends Component<Props, State> {
  state = {
    confirmPopupOpen: false,
    confirmPopupId: null,
    modalOpen: false,
    editingProducto: null
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    await this.props.getProductosSatActivos();
  };

  submit = (values: any) => {
    const { idProducto, nombre, clave } = values;

    const idParam = idProducto || null;
    const nombreParam = nombre || null;
    const claveParam = clave || null;

    let mensaje = "";
    if (idParam) mensaje += ` con ID ${idParam}`;
    if (nombreParam) mensaje += ` con nombre "${nombreParam}"`;
    if (claveParam) mensaje += ` con clave "${claveParam}"`;

    const result = this.props.getProductosSatActivosCustom(
      idParam,
      nombreParam,
      claveParam
    );

    result
      .then(response => {
        if (response.productosSat && response.productosSat.length === 0) {
          errorNotification(`No se encontraron productos${mensaje}`);
        } else {
          successNotification(`Productos encontrados${mensaje}`);
        }
      })
      .catch(error => {
        console.error("Error en la búsqueda:", error);
        errorNotification("Ocurrió un error al buscar productos");
      });
  };

  onAddClick = () => {
    this.setState({
      modalOpen: true,
      editingProducto: null
    });
  };

  onEditClick = (producto: ProductoSat) => {
    this.setState({
      modalOpen: true,
      editingProducto: producto
    });
  };

  onDeleteClick = (id: number) => {
    this.setState({
      confirmPopupOpen: true,
      confirmPopupId: id
    });
  };

  confirmDelete = async () => {
    const { confirmPopupId } = this.state;
    await this.props.desactivarProductoSAT(confirmPopupId);
    this.setState({
      confirmPopupOpen: false,
      confirmPopupId: null
    });
    this.fetchData();
  };

  render() {
    return (
      <Container className="mb-5">
        <FormBuilder<Inputs, Buttons>
          config={formularioBusquedaProductosSat}
          submit={this.submit}
          processing={this.props.searching}
        />

        <ProductosSatTable
          page={this.props.productosSat || []}
          onAddClick={this.onAddClick}
          onEditClick={this.onEditClick}
          onDeleteClick={this.onDeleteClick}
        />

        <ProductoSatModal
          isOpen={this.state.modalOpen}
          onClose={() => this.setState({ modalOpen: false })}
          dispatcher={this.props}
          producto={this.state.editingProducto}
          onProductoSaved={() => {
            this.fetchData();
          }}
          saving={this.props.saving}
        />

        <ConfirmModal
          title="Confirmar eliminación"
          message="¿Está seguro de eliminar este producto?"
          isOpen={this.state.confirmPopupOpen}
          ok={this.confirmDelete}
          cancel={() => this.setState({ confirmPopupOpen: false })}
        />
      </Container>
    );
  }
}
