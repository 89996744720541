import IAction from "../../types/IAction";
import actionTypes from "../actionTypes";
import constants from "../../utils/constants";
import { restActionFactory } from "../../toolkit/axiosActions/axiosActionsFactory";

const PRODUCTOS_SAT_BASE_URL = "/api/address/localidades";

export const getAllActiveEstados = (): IAction => {
  return codigoPostalRestAction({
    actionType: actionTypes.GET_ALL_ACTIVE_ESTADOS,
    urlComplement: `/estados`,
    method: "get"
  });
};

export const getAllActiveMunicipiosByEstado = (id: number): IAction => {
  return codigoPostalRestAction({
    actionType: actionTypes.GET_ALL_ACTIVE_MUNICIPIOS_BY_ESTADO,
    urlComplement: `/municipios/${id}`,
    method: "get"
  });
};

export const getLocalidadesDuplicadas = (
  municipioId: number,
  codigoId: number,
  localidad: string
): IAction => {
  return codigoPostalRestAction({
    actionType: actionTypes.GET_LOCALIDADES_DUPLICADAS,
    urlComplement: `/duplicadas`,
    method: "get",
    params: {
      municipioId: municipioId,
      codigoId: codigoId,
      localidad: localidad
    }
  });
};

export const guardarLocalidad = (body: any): IAction => {
  return codigoPostalRestAction({
    actionType: actionTypes.POST_LOCALIDAD,
    urlComplement: `/`,
    method: "post",
    body: body
  });
};

export const buscarLocalidades = (
  municipioId: number | null,
  codigoId: string | null,
  nombre: string | null
): IAction => {
  return codigoPostalRestAction({
    actionType: actionTypes.GET_LOCALIDADES_CUSTOM,
    urlComplement: `/`,
    method: "get",
    params: {
      municipioId: municipioId,
      codigoId: codigoId,
      nombre: nombre
    }
  });
};

export const actualizarLocalidad = (
  localidadId: number,
  body: any
): IAction => {
  return codigoPostalRestAction({
    actionType: actionTypes.PUT_LOCALIDAD,
    urlComplement: `/${localidadId}`,
    method: "put",
    body: body
  });
};

export const eliminarLocalidad = (localidadId: number): IAction => {
  return codigoPostalRestAction({
    actionType: actionTypes.DELETE_LOCALIDAD,
    urlComplement: `/${localidadId}`,
    method: "delete"
  });
};

const codigoPostalRestAction = restActionFactory(
  `${constants.BASE_URL}${PRODUCTOS_SAT_BASE_URL}`
);
