exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "span.i1HFF{cursor:pointer;font-size:16px;margin:0 8px}", ""]);

// exports
exports.locals = {
	"btn": "i1HFF"
};