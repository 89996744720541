import React from "react";
import _ from "lodash.isequal";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import BaseInput from "../../../toolkit/baseInput";
import { Button, Table } from "reactstrap";
import s from "./styles.scss";
import {
  ProspectArguments,
  Prospect,
  PARTNER_TYPES,
  PEOPLE_TYPES
} from "../../../types/Prospects";
import DatePicker from "../common/DatePicker";
import moment from "moment";
import isNil from "lodash.isnil";
import {
  errorNotification,
  successNotification,
  warnNotification,
  NOT_PERSON_FINDED
} from "../../../utils/notifications";
import { commonRegex } from "../../helpers/commonRegex";

interface Props {
  searchClients: (args: ProspectArguments) => any;
  searchClientByID: (id: string) => any;
  getPersonByNumeroEmpleado: (numeroEmpleado: string) => any;
  history: any;
  match: any;
  setSelectedPerson: (person: any) => any;
  createPerson: (person: object) => any;
  personLinkError: any[];
  getPersonaDatosBasicosParams: () => any;
  getCurpData: (body: object) => any;
  getPersonasRepetidasCurp: (curp: string) => any;
  personCreating: boolean;
}

enum SCREEN_MODES {
  SEARCHING,
  CREATING
}

enum Sexos {
  MASCULINO = "MASCULINO",
  FEMENINO = "FEMENINO"
}

interface Person {
  nombre: string;
  primerApellido: string;
  segundoApellido: string;
  fechaNacimiento: any;
  sexo: string;
  entidadNacimiento: string;
  bloqueoMail: boolean;
  numeroEmpleado: number;
  bloqueoCallCenter: boolean;
  tipoCliente: PARTNER_TYPES;
  tipo: PARTNER_TYPES;
  curpAprobada: boolean;
  curpPersona: string;
}

interface State {
  searchFields: {
    nombre: string;
    apellido: string;
    edad: any;
    sexo: Sexos;
    tipo: PEOPLE_TYPES;
  };
  errorMessage: boolean;
  personFields: Person;
  personaID: string;
  selectedPerson: string;
  screenMode: SCREEN_MODES;
  persons: Prospect[];
  personByID: Prospect;
  loading: boolean;
  gettingCurp: boolean;
  hasCurp: boolean;
  formFieldsDisabled: boolean;
  curpValidated: boolean;
  curpFormatValid: boolean;
  permisoOmitirCURP: boolean;
  consultarRenapo: boolean;
  verCampoCurp: boolean;
  showErrors: boolean;
}

const genreOptions = [
  { value: "", label: "Seleccione" },
  { value: "FEMENINO", label: "Femenino" },
  { value: "MASCULINO", label: "Masculino" }
];

const entidadNacimientoOptions = [
  { value: "", label: "Seleccione" },
  { value: "NACIONAL", label: "Nacional" },
  { value: "EXTRANJERO", label: "Extranjero" }
];

const typeOptions = [
  {
    label: "Cliente",
    value: PEOPLE_TYPES.CLIENTE
  },
  {
    label: "Cliente integrante",
    value: PEOPLE_TYPES.CLIENTE_INTEGRANTE
  },
  {
    label: "Cliente titular",
    value: PEOPLE_TYPES.CLIENTE_TITULAR
  },
  {
    label: "Externo",
    value: PEOPLE_TYPES.EXTERNO
  },
  {
    label: "Prospecto",
    value: PEOPLE_TYPES.PROSPECTO
  },
  {
    label: "Corporativo",
    value: PEOPLE_TYPES.CORPORATIVO
  },
  {
    label: "Empleado",
    value: PEOPLE_TYPES.EMPLEADO
  }
];

class AddPerson extends React.Component<Props, State> {
  state = {
    searchFields: {
      nombre: "",
      apellido: "",
      edad: 0,
      sexo: "",
      tipo: PEOPLE_TYPES.CLIENTE
    },
    personFields: {
      nombre: null,
      primerApellido: null,
      segundoApellido: null,
      fechaNacimiento: null,
      sexo: "",
      entidadNacimiento: "",
      bloqueoMail: false,
      bloqueoCallCenter: false,
      numeroEmpleado: null,
      tipoCliente: PARTNER_TYPES.EXTERNO,
      tipo: PARTNER_TYPES.EXTERNO,
      curpAprobada: true,
      curpPersona: ""
    },
    errorMessage: false,
    personaID: "",
    selectedPerson: null,
    screenMode: SCREEN_MODES.SEARCHING,
    personByID: null,
    persons: null,
    loading: false,
    gettingCurp: false,
    hasCurp: false,
    formFieldsDisabled: false,
    curpValidated: false,
    curpFormatValid: false,
    permisoOmitirCURP: false,
    consultarRenapo: false,
    verCampoCurp: true,
    showErrors: false
  };

  componentDidMount() {
    this.props
      .getPersonaDatosBasicosParams()
      .then(response => {
        const banderasPersonaResponse = response.value.data;

        this.setState({
          permisoOmitirCURP: banderasPersonaResponse.permisoOmitirCURP,
          consultarRenapo: banderasPersonaResponse.consultarRenapo,
          verCampoCurp: banderasPersonaResponse.verCampoCurp
          //permisoOmitirCURP: false,
          //consultarRenapo: true,
          //verCampoCurp: true
        });

        if (this.state.permisoOmitirCURP) {
          /* successNotification(
            "Tiene permiso para omitir la validación de CURP"
          ); */
        } else {
          warnNotification(
            "No tiene permiso para omitir la validación de CURP. La validación es obligatoria."
          );

          /* if (!this.state.consultarRenapo) {
            warnNotification("La validación de CURP se realizará localmente sin consultar API externa.");
          } */
        }
      })
      .catch(error => {
        console.error("Error al obtener parámetros:", error);
        errorNotification("Error al obtener banderas de persona");
      });
  }

  checkDuplicateCurps = curp => {
    return this.props
      .getPersonasRepetidasCurp(curp)
      .then(repetidosResponse => {
        const repetidos = repetidosResponse.value.data;

        if (repetidos && repetidos.length > 0) {
          const idsPersona = repetidos.map(rep => rep.idPersona).join(", ");
          const nombresCurp = repetidos
            .map(rep => rep.nombreCompleto)
            .join(", ");
          errorNotification(
            `CURP ya registrada. IDs de persona: ${idsPersona}. Nombres: ${nombresCurp}`
          );
        }

        return repetidos;
      })
      .catch(error => {
        console.error("Error al verificar repetidos CURP:", error);
        errorNotification("Error al verificar duplicados de CURP");
        return [];
      });
  };

  onChangeSearchFieldsFactory = fieldName => {
    return event => {
      let value;
      if (fieldName === "edad") {
        value = event;
      } else {
        value = event.target.value;
      }
      this.setState(state => {
        const searchFields = {
          ...state.searchFields,
          [fieldName]: value
        };

        return { ...state, searchFields };
      });
    };
  };

  onChangePersonFieldsFactory = fieldName => {
    return event => {
      let value;
      if (fieldName === "fechaNacimiento") {
        value = event;
      } else {
        value = (event.target.value || "").toUpperCase();
      }

      let curpFormatValid = this.state.curpFormatValid;
      let curpValidated = this.state.curpValidated;
      let hasCurp = this.state.hasCurp;
      let updatedPartnerFields = { ...this.state.personFields };

      if (fieldName === "curpPersona" && !this.state.permisoOmitirCURP) {
        curpFormatValid = value && commonRegex.curp.test(value);

        if (this.state.curpValidated) {
          curpValidated = false;

          if (this.state.consultarRenapo && this.state.hasCurp) {
            hasCurp = true;
            updatedPartnerFields = {
              ...updatedPartnerFields,
              nombre: "",
              primerApellido: "",
              segundoApellido: "",
              fechaNacimiento: null,
              sexo: ""
            };
          } else {
            hasCurp = false;
          }
        }
      }

      updatedPartnerFields[fieldName] = value;

      this.setState({
        personFields: updatedPartnerFields,
        curpFormatValid:
          fieldName === "curpPersona" && !this.state.permisoOmitirCURP
            ? curpFormatValid
            : this.state.curpFormatValid,
        curpValidated:
          fieldName === "curpPersona" && !this.state.permisoOmitirCURP
            ? curpValidated
            : this.state.curpValidated,
        hasCurp:
          fieldName === "curpPersona" && !this.state.permisoOmitirCURP
            ? hasCurp
            : this.state.hasCurp
      });
    };
  };

  updateStateWithRenapoData = (renapoCurpResponse, sexoValue) => {
    this.setState({
      personFields: {
        ...this.state.personFields,
        nombre: renapoCurpResponse.nombre || this.state.personFields.nombre,
        primerApellido:
          renapoCurpResponse.primerApellido ||
          this.state.personFields.primerApellido,
        segundoApellido:
          renapoCurpResponse.segundoApellido ||
          this.state.personFields.segundoApellido,
        fechaNacimiento: renapoCurpResponse.fechaNacimiento,
        sexo: sexoValue,
        curpPersona: this.state.personFields.curpPersona
      },
      hasCurp: true,
      gettingCurp: false,
      loading: false,
      curpValidated: true,
      curpFormatValid: true
    });
  };

  searchClients = async e => {
    e.preventDefault();
    const args = { ...this.state.searchFields };

    this.setState({
      loading: true
    });

    try {
      if (this.state.personaID && args.tipo == PEOPLE_TYPES.EMPLEADO) {
        const {
          value: { data: personByID }
        } = await this.props.getPersonByNumeroEmpleado(this.state.personaID);

        if (!personByID) {
          errorNotification(
            `No se encontró empleado con número: ${this.state.personaID}`
          );
        }

        this.setState({
          screenMode: SCREEN_MODES.SEARCHING,
          personByID,
          persons: null,
          errorMessage: true,
          loading: false
        });
      } else if (this.state.personaID) {
        const {
          value: { data: personByID }
        } = await this.props.searchClientByID(this.state.personaID);

        if (!personByID) {
          errorNotification(
            `No se encontró persona con ID: ${this.state.personaID}`
          );
        }

        this.setState({
          screenMode: SCREEN_MODES.SEARCHING,
          personByID,
          persons: null,
          errorMessage: true,
          loading: false
        });
      } else {
        if (!!args.nombre && !!args.nombre.length) {
          args.nombre = args.nombre.trim();
        }
        if (!!args.apellido && !!args.apellido.length) {
          args.apellido = args.apellido.trim();
        }
        args.sexo = args.sexo || null;

        const {
          value: { data: persons }
        } = await this.props.searchClients(args);

        if (!persons || persons.length === 0) {
          errorNotification("No se encontraron resultados para la búsqueda");
        }

        this.setState({
          screenMode: SCREEN_MODES.SEARCHING,
          persons,
          personByID: null,
          errorMessage: true,
          loading: false
        });
      }
    } catch (error) {
      errorNotification("Error al realizar la búsqueda. Intente nuevamente.");
      console.error("Error searching clients:", error);
      this.setState({
        loading: false
      });
    }
  };

  newParter = e => {
    e.preventDefault();
    this.setState({ screenMode: SCREEN_MODES.CREATING });
  };

  onChangePersonaID = event => {
    const personaID = event.target.value;
    this.setState({ ...this.state, personaID });
  };

  selectPerson = id => {
    this.setState({ selectedPerson: id });
  };

  getPersonsRender = () => {
    const personsToRender =
      this.state.persons ||
      (this.state.personByID ? [this.state.personByID] : null);
    if (
      !isNil(personsToRender) &&
      personsToRender.length == 0 &&
      this.state.errorMessage
    ) {
      this.setState({ errorMessage: false });
      errorNotification(NOT_PERSON_FINDED);
    }
    return personsToRender && personsToRender.length > 0 ? (
      <Table size="sm" hover className="table-borderless">
        <thead>
          <tr>
            <th>Persona Id</th>
            <th>Nombre</th>
            <th>A. Paterno</th>
            <th>A. Materno</th>
            <th>Número</th>
            <th>Correo</th>
            <th>F. Nacimiento</th>
            <th>Tipo</th>
          </tr>
        </thead>
        <tbody>
          {personsToRender && personsToRender.length > 0
            ? personsToRender.map(prospect => (
                <tr
                  className={s.personRow}
                  style={
                    prospect.personaId === this.state.selectedPerson
                      ? { backgroundColor: "rgba(0, 0, 0, 0.075)" }
                      : {}
                  }
                  onClick={() => this.selectPerson(prospect.personaId)}
                  key={prospect.personaId}
                >
                  <th scope="row">{prospect.personaId}</th>
                  <td>{prospect.nombre}</td>
                  <td>{prospect.primerApellido}</td>
                  <td>{prospect.segundoApellido || ""}</td>
                  <td>
                    {prospect.telefonos &&
                      prospect.telefonos.length &&
                      prospect.telefonos[0].numero}
                  </td>
                  <td>
                    {prospect.correosElectronicos &&
                      prospect.correosElectronicos.length &&
                      prospect.correosElectronicos[0].correo}
                  </td>
                  <td>{prospect.fechaNacimiento}</td>
                  <td>{prospect.tipo}</td>
                </tr>
              ))
            : null}
        </tbody>
      </Table>
    ) : null;
  };

  validCreatingForm = () => {
    const requiredFields = [
      "nombre",
      "primerApellido",
      "segundoApellido",
      "fechaNacimiento",
      "sexo",
      "entidadNacimiento"
    ];

    if (!this.state.permisoOmitirCURP) {
      requiredFields.push("curpPersona");
    }

    const missingFields = requiredFields.filter(
      field =>
        !this.state.personFields[field] ||
        (typeof this.state.personFields[field] === "string" &&
          this.state.personFields[field].trim() === "")
    );

    const curpValid =
      this.state.permisoOmitirCURP ||
      (this.state.personFields.curpPersona && this.state.curpValidated);

    return missingFields.length === 0 && curpValid;
  };

  linkPerson = e => {
    e.preventDefault();

    this.setState({ showErrors: true });

    if (this.state.screenMode === SCREEN_MODES.SEARCHING) {
      if (!this.state.selectedPerson) {
        errorNotification("Debe seleccionar una persona para continuar");
        return;
      }

      if (
        this.state.personByID &&
        this.state.personByID.personaId === this.state.selectedPerson
      ) {
        this.props.setSelectedPerson(this.state.personByID);
      } else if (this.state.persons.length > 0) {
        const person = this.state.persons.filter(
          persona => persona.personaId === this.state.selectedPerson
        );

        if (person.length > 0) {
          this.props.setSelectedPerson(person[0]);
        } else {
          errorNotification(
            "Error al seleccionar la persona. Inténtelo nuevamente."
          );
          return;
        }
      }
    } else if (this.state.screenMode === SCREEN_MODES.CREATING) {
      if (!this.validCreatingForm()) {
        errorNotification(
          "Por favor complete todos los campos requeridos correctamente"
        );
        return;
      }

      this.props.createPerson({
        ...this.state.personFields,
        fechaNacimiento: moment(this.state.personFields.fechaNacimiento).format(
          "YYYY-MM-DD"
        )
      });
    }
  };

  verifyCurp = e => {
    e.preventDefault();
    this.setState({ gettingCurp: true, loading: true });

    this.checkDuplicateCurps(this.state.personFields.curpPersona)
      .then(repetidos => {
        if (repetidos && repetidos.length > 0) {
          this.setState({
            gettingCurp: false,
            loading: false
          });
          return;
        }

        if (this.state.consultarRenapo) {
          this.props
            .getCurpData({
              curp: this.state.personFields.curpPersona,
              apiCurpEstatus: "NO_INTENTADO"
            })
            .then(curpResponse => {
              const renapoCurpResponse = curpResponse.value.data;
              let sexo = this.state.personFields.sexo;
              if (renapoCurpResponse.sexo) {
                if (renapoCurpResponse.sexo === "HOMBRE") {
                  sexo = Sexos.MASCULINO;
                } else if (renapoCurpResponse.sexo === "MUJER") {
                  sexo = Sexos.FEMENINO;
                }
              }
              this.updateStateWithRenapoData(renapoCurpResponse, sexo);
            })
            .catch(error => {
              console.error("Error al verificar CURP con API Externa:", error);
              this.setState({
                gettingCurp: false,
                loading: false
              });

              const message =
                error &&
                error.response &&
                error.response.data &&
                error.response.data.message;
              errorNotification(message || "Error al validar CURP");
            });
        } else {
          const curpFormatValid = commonRegex.curp.test(
            this.state.personFields.curpPersona.toUpperCase()
          );
          this.setState({
            gettingCurp: false,
            loading: false,
            curpFormatValid,
            curpValidated: curpFormatValid
          });
          if (!curpFormatValid) {
            errorNotification("El formato de la CURP no es válido");
          } else {
            successNotification(
              "Formato CURP validado correctamente localmente"
            );
          }
        }
      })
      .catch(error => {
        console.error("Error al verificar duplicados de CURP:", error);
        this.setState({
          gettingCurp: false,
          loading: false
        });
        errorNotification("Error al verificar CURP");
      });
  };

  selectPartnerType = e => {
    const tipoCliente = e.target.value;
    const tipo = e.target.value;
    this.setState(state => ({
      ...state,
      personFields: {
        ...state.personFields,
        tipoCliente,
        tipo,
        numeroEmpleado:
          tipoCliente !== PARTNER_TYPES.EMPLEADO
            ? null
            : state.personFields.numeroEmpleado
      }
    }));
  };

  render() {
    return (
      <div className={s.modalContainer}>
        <div className="h-100">
          {this.props.personLinkError &&
            this.props.personLinkError.map((message, index) => {
              return (
                <span key={index} className={s.errorSpan}>
                  {message}
                  <br />
                </span>
              );
            })}
          <form className={s.basicDataClub}>
            <div className="form-row">
              <div className="col">
                <BaseInput
                  label={
                    this.state.searchFields.tipo == PEOPLE_TYPES.EMPLEADO
                      ? "No. de Empleado"
                      : "Persona Id"
                  }
                  name="personaID"
                  type="number"
                  id="personaID"
                  placeholder={
                    this.state.searchFields.tipo == PEOPLE_TYPES.EMPLEADO
                      ? "No. de Empleado"
                      : "Persona Id"
                  }
                  value={this.state.personaID}
                  onChange={e => this.onChangePersonaID(e)}
                  disabled={
                    this.state.searchFields.apellido ||
                    this.state.searchFields.nombre
                  }
                />
              </div>
              <div className="col">
                <BaseInput
                  label={"Nombre"}
                  name="nombre"
                  type="text"
                  id="nombre"
                  placeholder="Nombre"
                  value={this.state.searchFields.nombre}
                  disabled={this.state.personaID}
                  onChange={this.onChangeSearchFieldsFactory("nombre")}
                />
              </div>
              <div className="col">
                <BaseInput
                  label={"Apellido"}
                  name="apellido"
                  type="text"
                  id="apellido"
                  placeholder="Apellido"
                  value={this.state.searchFields.apellido}
                  disabled={this.state.personaID}
                  onChange={this.onChangeSearchFieldsFactory("apellido")}
                />
              </div>
              <div className="col">
                <BaseInput
                  label={"Sexo"}
                  name="sexo"
                  type="select"
                  id="sexo"
                  placeholder="Sexo"
                  value={this.state.searchFields.sexo}
                  disabled={this.state.personaID}
                  options={genreOptions}
                  onChange={this.onChangeSearchFieldsFactory("sexo")}
                />
              </div>
              <div className="col">
                <DatePicker
                  label="Edad"
                  name="edad"
                  id="edad"
                  selected={this.state.searchFields.edad}
                  onChange={this.onChangeSearchFieldsFactory("edad")}
                />
              </div>
              <div className="col">
                <BaseInput
                  label={"Tipo de persona"}
                  name="tipo"
                  type="select"
                  id="tipo"
                  placeholder="Tipo de persona"
                  value={this.state.searchFields.tipo}
                  disabled={this.state.personaID}
                  options={typeOptions}
                  onChange={this.onChangeSearchFieldsFactory("tipo")}
                />
              </div>
              <div className="col-1">
                <Button
                  className={`${s.buttonMarginTop} ${s.primaryButton} mt-4`}
                  onClick={e => this.searchClients(e)}
                  size="sm"
                  block
                  disabled={
                    (!this.state.personaID &&
                      !(
                        this.state.searchFields.apellido &&
                        this.state.searchFields.nombre
                      )) ||
                    this.state.loading
                  }
                >
                  {!this.state.loading ? "Buscar" : "Espere"}
                </Button>
              </div>
              <div className="col-1">
                <Button
                  className={`${s.buttonMarginTop} ${s.primaryButton} mt-4`}
                  size="sm"
                  block
                  onClick={e => this.newParter(e)}
                  disabled={this.state.loading}
                >
                  {"Nuevo"}
                </Button>
              </div>
            </div>
          </form>
          <hr />
          {this.state.screenMode === SCREEN_MODES.SEARCHING ? (
            this.getPersonsRender()
          ) : this.state.screenMode === SCREEN_MODES.CREATING ? (
            <form className={s.basicDataClub}>
              <div className="form-row">
                <div className="col">
                  <BaseInput
                    label={"Nacionalidad"}
                    name="entidadNacimiento"
                    type="select"
                    options={entidadNacimientoOptions}
                    id="entidadNacimiento"
                    placeholder="Entidad Nacimiento"
                    value={this.state.personFields.entidadNacimiento}
                    onChange={this.onChangePersonFieldsFactory(
                      "entidadNacimiento"
                    )}
                    errors={
                      this.state.showErrors &&
                      (!this.state.personFields.entidadNacimiento ||
                        this.state.personFields.entidadNacimiento === "")
                        ? ["Debe seleccionar una entidad de nacimiento"]
                        : []
                    }
                  />
                </div>
                {!this.state.permisoOmitirCURP && (
                  <>
                    <div className="col">
                      <BaseInput
                        label={"CURP"}
                        name="CURP"
                        type="text"
                        id="curp"
                        uppercase={true}
                        placeholder="CURP"
                        value={this.state.personFields.curpPersona}
                        onChange={this.onChangePersonFieldsFactory(
                          "curpPersona"
                        )}
                        errors={
                          this.state.personFields.curpPersona &&
                          !this.state.curpFormatValid &&
                          !this.state.permisoOmitirCURP
                            ? ["La CURP ingresada no es válida"]
                            : this.state.showErrors &&
                              (!this.state.personFields.curpPersona ||
                                this.state.personFields.curpPersona === "") &&
                              !this.state.permisoOmitirCURP
                            ? ["Este campo es obligatorio"]
                            : []
                        }
                      />
                    </div>
                    <div className="col-2">
                      <Button
                        className={`${s.buttonMarginTop} ${s.primaryButton} mt-4`}
                        size="sm"
                        block
                        onClick={this.verifyCurp}
                        disabled={
                          !this.state.personFields.curpPersona ||
                          !this.state.personFields.entidadNacimiento ||
                          this.state.personFields.entidadNacimiento === "" ||
                          this.state.loading ||
                          (this.state.curpValidated &&
                            this.state.consultarRenapo) ||
                          (!this.state.permisoOmitirCURP &&
                            !this.state.curpFormatValid)
                        }
                      >
                        {this.state.curpValidated
                          ? "CURP validado"
                          : !this.state.loading
                          ? "Verificar CURP"
                          : "Verificando..."}
                      </Button>
                    </div>
                  </>
                )}
                <div className="col">
                  <BaseInput
                    label={"Nombre"}
                    name="nombre"
                    type="text"
                    id="nombre"
                    placeholder="Nombre"
                    value={this.state.personFields.nombre}
                    onChange={this.onChangePersonFieldsFactory("nombre")}
                    disabled={
                      !(
                        this.state.permisoOmitirCURP ||
                        (this.state.curpValidated &&
                          !this.state.consultarRenapo)
                      )
                    }
                    errors={
                      this.state.showErrors &&
                      (!this.state.personFields.nombre ||
                        this.state.personFields.nombre === "")
                        ? ["Este campo es obligatorio"]
                        : []
                    }
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="col">
                  <BaseInput
                    label={"Apellido Paterno"}
                    name="primerApellido"
                    type="text"
                    id="primerApellido"
                    placeholder="Apellido Paterno"
                    value={this.state.personFields.primerApellido}
                    onChange={this.onChangePersonFieldsFactory(
                      "primerApellido"
                    )}
                    disabled={
                      !(
                        this.state.permisoOmitirCURP ||
                        (this.state.curpValidated &&
                          !this.state.consultarRenapo)
                      )
                    }
                    errors={
                      this.state.showErrors &&
                      (!this.state.personFields.primerApellido ||
                        this.state.personFields.primerApellido === "")
                        ? ["Este campo es obligatorio"]
                        : []
                    }
                  />
                </div>
                <div className="col">
                  <BaseInput
                    label={"Apellido Materno"}
                    name="segundoApellido"
                    type="text"
                    id="segundoApellido"
                    placeholder="Apellido Materno"
                    value={this.state.personFields.segundoApellido}
                    onChange={this.onChangePersonFieldsFactory(
                      "segundoApellido"
                    )}
                    disabled={
                      !(
                        this.state.permisoOmitirCURP ||
                        (this.state.curpValidated &&
                          !this.state.consultarRenapo)
                      )
                    }
                    errors={
                      this.state.showErrors &&
                      (!this.state.personFields.segundoApellido ||
                        this.state.personFields.segundoApellido === "")
                        ? ["Este campo es obligatorio"]
                        : []
                    }
                  />
                </div>
                <div className="col">
                  <DatePicker
                    label="Fecha de Nacimiento"
                    name="fechaNacimiento"
                    id="fechaNacimiento"
                    selected={this.state.personFields.fechaNacimiento}
                    onChange={this.onChangePersonFieldsFactory(
                      "fechaNacimiento"
                    )}
                    disabled={
                      !(
                        this.state.permisoOmitirCURP ||
                        (this.state.curpValidated &&
                          !this.state.consultarRenapo)
                      )
                    }
                    errors={
                      this.state.showErrors &&
                      (!this.state.personFields.fechaNacimiento ||
                        this.state.personFields.fechaNacimiento === null)
                        ? ["La fecha de nacimiento es obligatoria"]
                        : []
                    }
                  />
                </div>
                <div className="col">
                  <BaseInput
                    label={"Sexo"}
                    name="sexo"
                    type="select"
                    options={genreOptions}
                    id="sexo"
                    placeholder="Sexo"
                    value={this.state.personFields.sexo}
                    onChange={this.onChangePersonFieldsFactory("sexo")}
                    disabled={
                      !(
                        this.state.permisoOmitirCURP ||
                        (this.state.curpValidated &&
                          !this.state.consultarRenapo)
                      )
                    }
                    errors={
                      this.state.showErrors &&
                      (!this.state.personFields.sexo ||
                        this.state.personFields.sexo === "")
                        ? ["Debe seleccionar un sexo"]
                        : []
                    }
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="col p-0">&nbsp;</div>
                <div className="col mt-4">
                  <div
                    className="row m-0"
                    style={{
                      position: "absolute",
                      bottom: "0.25rem",
                      left: "1.25rem"
                    }}
                  >
                    <label className="px-1">
                      <input
                        type="radio"
                        name="optionExternoPartnerType"
                        id="optionExternoPartnerType"
                        value={PARTNER_TYPES.EXTERNO}
                        onChange={this.selectPartnerType}
                        checked={
                          this.state.personFields.tipoCliente ===
                          PARTNER_TYPES.EXTERNO
                        }
                      />
                      {` ${PARTNER_TYPES.EXTERNO}`}
                    </label>
                    <label className="px-1">
                      <input
                        type="radio"
                        name="optionCorporativoPartnerType"
                        id="optionCorporativoPartnerType"
                        value={PARTNER_TYPES.CORPORATIVO}
                        onChange={this.selectPartnerType}
                        checked={
                          this.state.personFields.tipoCliente ===
                          PARTNER_TYPES.CORPORATIVO
                        }
                      />
                      {` ${PARTNER_TYPES.CORPORATIVO}`}
                    </label>
                  </div>
                </div>
              </div>
            </form>
          ) : null}
        </div>
        <form>
          <div className="form-row p-0 m-0">
            <div className="col" />
            <div className="col-1 float-right pr-0">
              <Button
                className={`${s.buttonMarginTop} ${s.primaryButton} mt-4`}
                onClick={e => this.linkPerson(e)}
                size="sm"
                block
                disabled={this.props.personCreating}
              >
                {this.state.screenMode === SCREEN_MODES.SEARCHING
                  ? "Asociar"
                  : "Guardar"}
              </Button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default withRouter(withStyles(s)(AddPerson));
