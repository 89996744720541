import { Preview } from "../../../types/IAction";
import * as actions from "../../actions/codigoPostal";

export interface CodigoPostalDispatcher {
  getAllActiveEstados: () => Promise<Preview<any>>;
  getAllActiveMunicipiosByEstado: (id: number) => Promise<Preview<any>>;
  getLocalidadesDuplicadas: (
    municipioId: number,
    codigoId: number,
    localidad: string
  ) => Promise<Preview<any>>;
  guardarLocalidad: (body: any) => Promise<Preview<any>>;
  buscarLocalidades: (
    municipioId: number,
    codigoId: string,
    nombre: string
  ) => Promise<Preview<any>>;
  actualizarLocalidad: (
    localidadId: number,
    body: any
  ) => Promise<Preview<any>>;
  eliminarLocalidad: (localidadId: number) => Promise<Preview<any>>;
}

export const codigoPostalDispatcher: (
  dispatch: (...args) => Promise<Preview<any>>
) => CodigoPostalDispatcher = dispatch => ({
  getAllActiveEstados: () => {
    return dispatch(actions.getAllActiveEstados());
  },
  getAllActiveMunicipiosByEstado: (id: number) => {
    return dispatch(actions.getAllActiveMunicipiosByEstado(id));
  },
  getLocalidadesDuplicadas: (
    municipioId: number,
    codigoId: number,
    localidad: string
  ) => {
    return dispatch(
      actions.getLocalidadesDuplicadas(municipioId, codigoId, localidad)
    );
  },
  guardarLocalidad: (body: any) => {
    return dispatch(actions.guardarLocalidad(body));
  },
  buscarLocalidades: (
    municipioId: number,
    codigoId: string,
    nombre: string
  ) => {
    return dispatch(actions.buscarLocalidades(municipioId, codigoId, nombre));
  },
  actualizarLocalidad: (localidadId: number, body: any) => {
    return dispatch(actions.actualizarLocalidad(localidadId, body));
  },
  eliminarLocalidad: (localidadId: number) => {
    return dispatch(actions.eliminarLocalidad(localidadId));
  }
});
